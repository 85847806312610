.teams-time-picker-container{
    position: relative;
    width: 100%;

    > .teams-time-picker-input{
        width: 100%;
        height: 32px;

        input{
            width: 100%;
            padding-right: 30px;
            height: 30px;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 30px;
        }
        .ui-input__icon{
            right: 32px;
        }
    }

    > .teams-time-picker-button{
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none;
        outline: none;
        display: flex;
        height: 30px;
        width: 30px;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        svg{
            fill: rgb(173, 173, 173) !important;
        }
    }

    > .ui-popup__content{
        width: 100%;

        > .ui-popup__content__content{
            padding: 0.5714rem 0 0.4286rem 0;

            > .teams-time-picker-list-items{
                height: 123px;
                overflow-y: auto;

                > li {
                    padding: 0.2857rem 0.7857rem;
                    min-height: 32px;
                }
            }
        }
    }
}