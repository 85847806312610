@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.dyk-wrap {
    height: 100% !important;
    display: grid !important;
    align-items: center !important;
}

.dyk-bg1 {
    width: 100%;
    height: 100%;
    position: absolute;
    background: radial-gradient(61.5% 61.5% at 101.38% 100%, #B6C6FF 0%, rgba(255, 238, 252, 0) 100%), radial-gradient(52.75% 93.78% at 6.37% 100%, #FFE6B5 0%, rgba(250, 255, 238, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
}

.dyk-container {
    font-family: "Open Sans", sans-serif !important;
    font-style: italic !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #212529 !important;
}

.dyk-header {
    display: inline-flex;
    clip-path: polygon(7% 0, 100% 0%, 94% 100%, 0% 100%);
}

.dyk-ht {
    font-size: 40px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
}

.dyk-quote {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.dyk-qm {
    position: relative;
    font-size: 96px;
    line-height: 0;
    color: #fff;
    font-weight: 700 !important;
    z-index: 5;
    text-shadow: 2px 2px #6264a7, -12px -4px #6264a7b9, 11px 12px #6264a768;
}

.card-dyk {
    position: relative;
    min-width: 10rem;
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    .card-dyk-inner {
        padding: 1rem;
        border-radius: 1rem;
        width: 100%;
        text-align: center !important;

        h {
            color: rgb(27, 27, 27);
            font-size: 16px !important;
            font-weight: 400 !important;
            margin: 0;
        }

        p {
            color: rgb(27, 27, 27);
            text-align: left !important;
            margin-top: 1rem;
            margin-bottom: 0;
            font-size: .8rem;

            a {
                font-size: 12.8px !important;
                font-weight: 400 !important;
            }
        }
    }

    .card-dyk-inner-clp {
        z-index: 50;
        background-color: #6264a7;
        padding: 1rem;
        width: 100%;
        border-radius: 1rem;
        position: absolute;
        clip-path: circle(10% at 0% 0%);
        transition: clip-path .3s ease-in-out;
        text-align: center !important;

        h {
            font-size: 16px !important;
            font-weight: 400 !important;
            color: #fff;
            margin: 0;
        }

        p {
            text-align: left !important;
            font-size: .8rem;
            margin-top: 1rem;
            margin-bottom: 0;

            a {
                font-size: 12.8px !important;
                font-weight: 400 !important;

                &:hover {
                    .card-dyk-inner p {
                        a {
                            text-decoration-line: underline;
                            color: #e9ffb5;
                        }
                    }

                    text-decoration-line: underline;
                    color: #e9ffb5;
                }

                //color: #ffc107 ;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }

    // start of multiple motion circles
    .card-dyk-inner-clpx {
        z-index: 50;
        background-color: transparent;
        padding: 1rem;
        width: 100%;
        border-radius: 1rem;
        position: absolute;
        clip-path: circle(10% at 0% 0%);
        transition: clip-path .3s ease-in-out;
        text-align: center !important;

        h {
            font-size: 16px !important;
            font-weight: 400 !important;
            color: #fff;
            margin: 0;
        }

        p {
            text-align: left !important;
            font-size: .8rem;
            margin-top: 1rem;
            margin-bottom: 0;

            a {
                font-size: 12.8px !important;
                font-weight: 400 !important;

                &:hover {
                    .card-dyk-inner p {
                        a {
                            text-decoration-line: underline;
                            color: #e9ffb5;
                        }
                    }

                    text-decoration-line: underline;
                    color: #e9ffb5;
                }

                //color: #ffc107 ;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }

    .card-dyk-inner-clp1 {
        z-index: 1;
        padding: 1rem;
        width: 100%;
        height: 100%;
        background-color: #6264a7;
        border-radius: 1rem;
        position: absolute;
        clip-path: circle(10% at 0% 0%);
        transition: clip-path .3s ease-in-out;
        text-align: center !important;
    }

    .card-dyk-inner-clp1a {
        z-index: 2;
        padding: 1rem;
        width: 100%;
        height: 100%;
        background-color: #e9ffb5;
        //background-color: #ffc107;
        border-radius: 1rem;
        position: absolute;
        clip-path: circle(10% at 110% 110%);
        transition: clip-path .3s ease-in-out;
        text-align: center !important;
    }

    .card-dyk-inner-clp1b {
        z-index: 3;
        padding: 1rem;
        width: 100%;
        height: 100%;
        // background-color: #ffa4e5;
        background-color: #857de5;
        ;
        border-radius: 1rem;
        position: absolute;
        clip-path: circle(0% at 40% 56%);
        transition: clip-path .3s ease-in-out;
        text-align: center !important;
    }

    //end of multiple motion circles

    &:hover {
        .card-dyk-inner-clp {
            clip-path: circle(125% at 0% 0%);
        }

        .card-dyk-inner-clpx {
            clip-path: circle(125% at 0% 0%);
        }

        .card-dyk-inner-clp1 {
            clip-path: circle(125% at 0% 0%);
        }

        .card-dyk-inner-clp1a {
            clip-path: circle(65% at 0% 0%);
        }

        .card-dyk-inner-clp1b {
            clip-path: circle(80% at 0% 0%);
        }
    }

}


//current projected dashboard css
body.dark {
    #main {
        background-color: #313131;

        .card {
            background-color: #313131;
            box-shadow: 0px 6px 12px -6px rgb(0, 0, 0);
        }
    }

}