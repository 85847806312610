@import '../../../../styles/media-queries.scss';

body.dark {
    
}

body.default {
    
}

body.contrast {

}

.overview-content-holder {
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    transition: transform 0.3s ease;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr 4fr;
    grid-gap: 15px;

    &.finalizing {
        opacity: 0.5;
    }

    > .commentsContainer {
        grid-column: 1/3;
        grid-row: 1/2;
        display: grid;
    }

    > .videoContainer{
        overflow:hidden;
    } 
    @include lt-sm {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        overflow-y: auto;
        overflow-x: hidden;

        > .commentsContainer {
            grid-column: unset;
            grid-row: unset;
        }
    }
}


.steps-container{
    text-align:center;

    .steps {
        padding-left: 0;
        margin: 0;

        > .step {
            width: 12px;
            height: 12px;
            border: none;
            background: #ebebeb;
            border-radius: 50%;
            margin: 0 2px;
            cursor: pointer;
            display: inline-block;
            opacity: 0.5;
    
            &.finish {
                background-color: #28a745;
            }
    
            &.active {
                opacity: 1;
                width: 26px;
                transition: all 0.5s ease;
                border-radius: 8px;
                
            }        
        }
    }
}

.btn-skip {
    height: auto;

    > .ui-box{
        margin-left: 0;
    }
}

.overview-loader{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}