.bg-4B549A {
    background-color: #4B549A !important;
}

.bg-B6B5D550 {
    background-color: #B6B5D550 !important;
}

.fs-1_25 {
    font-size: 1.25rem !important;
}

.fs-1_5 {
    font-size: 1.5rem !important;
}

.fs-1_75 {
    font-size: 1.75rem !important;
}

.lh-1_25 {
    line-height: 1.25 !important;
}

.overflow-clip {
    overflow: clip;
}

.a365-100vh {
    height: 100vh !important;
}

.a365-br-8 {
    border-radius: .5rem !important;
}

.a365-div-center {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    // position: absolute;
    // transform: translate(-50%, -50%);
    // top: 50%;
    // left: 50%;
    place-self: center;
}

.d-grid {
    display: grid;
    min-height: 100vh;
}