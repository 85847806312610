body.dark {
  .datePickerContainer {
    .datePickerLabel {
      // border-bottom-color: rgb(166, 167, 220);
      background-color: #333;

      .ui-icon {
        > svg {
          &:hover {
            fill: rgb(166, 167, 220);
          }
        }
      }
    }
  }
}

body.contrast {
  .datePickerContainer {
    .datePickerLabel {
      border: 0.0625rem solid rgb(255, 255, 255);
      background-color: rgb(0, 0, 0);

      .ui-icon {
        > svg {
          &:hover {
            fill: rgb(26, 235, 255);
          }
        }
      }
    }
  }
}

body.default {
  .datePickerContainer {
    .datePickerLabel {
      // border-bottom-color: rgb(98, 100, 167) ;
      background-color: #ebebeb;

      .ui-icon {
        > svg {
          &:hover {
            fill: rgb(98, 100, 167);
          }
        }
      }
    }
  }
}

.datePickerContainer {
  width: 167px;
  position: relative;
  .datePicker {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 100%;
  }

  .datePickerLabel {
    width: 100%;
    padding: 0.3571rem 0.8571rem;
    margin-bottom: 0;
    position: relative;
    // border-bottom-style: solid;
    // border-bottom-width: 0.125rem;
    border-radius: 0.1875rem 0.1875rem 0.125rem 0.125rem;
    line-height: 1.85;

    .ui-icon {
      position: absolute;
      right: calc(0.8571rem + 4px);
      top: calc(50% - 9px);

      > svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}
