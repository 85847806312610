@import '../../../styles/media-queries.scss';

.edit_user_container {
    overflow: auto;

    @include lt-sm {
        min-width: unset !important;
        width: 100%;
    }
}

.user-profile-header {
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;
    justify-content: unset !important;

    @include lt-sm {
        //grid-template-rows: 1fr 1fr;
    }
}

.no-data-icon {
    @include lt-sm {
        margin-top: 1em !important;
    }
}

.ui-provider {
    >.ui-tooltip__content {
        z-index: 99999999;
    }

    >.ui-popup__content {
        z-index: 999999;
    }
}

.subscriptionDropDown {
    >.ui-dropdown {
        >.ui-dropdown__container {
            >.ui-dropdown__selected-items {
                max-width: 130px;
                min-width: 130px;
            }
        }
    }
}

.k-grid {
    >.k-grid-container {
        // min-height: 250px;
    }
}


.k-dialog-wrapper {
    z-index: 1001 !important;
}

.k-detail-row {
    >.k-detail-cell {
        overflow: visible;
    }
}

/*
.k-grid {
    > .k-grid-container{
        overflow: visible !important;

        > .k-grid-content{
            overflow: visible !important;
        }
    }
}
*/

.ui-dropdown__selecteditem {
    &._disabledItem {
        background-color: rgb(211, 211, 211);
        color: #999;
    }
}

.ui-dropdown__item {
    &._disabledItem {
        >.ui-dropdown__item__main {
            >.ui-dropdown__item__header {
                color: rgb(170, 170, 170);
            }
        }
    }
}

.ui-dropdown__item {
    &._licenseUnavailable {
        >.ui-dropdown__item__main {
            >.ui-dropdown__item__header {
                color: rgb(170, 170, 170);
            }

            >.ui-dropdown__item__content {
                color: red;
            }
        }
    }
}


.profile-link {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.user-profile {
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;

    >.ui-segment {
        &.focused {
            border: 2px solid #ccc;
        }
    }

    @include lt-sm {
        grid-template-columns: 100% !important;
        grid-template-rows: auto 1fr;

        .user-profile-managerlisting {
            height: 100% !important;
            max-height: 330px;
        }

        .user-profile-groupslisting {
            height: 100% !important;
            max-height: 330px;
        }
    }
}

.user-info {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
}

.whRow {
    @include lt-sm {
        grid-template-columns: 100% !important;
    }

    .ui-dropdown__container {
        max-width: 100%;
    }
}

.timePicker,
.datePicker {
    >.ui-box {
        width: 100%;

        input.ui-box {
            width: 100%;
            padding: 0.3571rem 2.5rem 0.3571rem 0.8571rem;

            &::-webkit-calendar-picker-indicator {
                font-size: 16px;
                cursor: pointer;
            }

            &:not(:required) {
                padding: 0.3571rem 0.8571rem;
            }
        }
    }
}

.editBtnGroup {
    display: flex;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;

    @include lt-sm {
        >button.ui-button {
            min-width: auto;
            padding: 8px 12px;

            >span.ui-button__content {
                font-size: 0.8em;
            }
        }
    }
}


.textOverFlow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: start;
}

.leftSideBar {
    min-width: 200px;
}

.rightSideBar {
    max-width: calc(100% - 200px);

    @include lt-sm {
        max-width: 100%;
    }
}

.permissions-group {
    >.ui-grid {
        >*:first-child {
            text-align: left;
        }

        >*:last-child {
            text-align: left;
        }

        &:not(.whRow) {
            grid-template-columns: 140px 180px calc(100% - 330px) !important;
        }
    }

    @include lt-sm {
        .ui-dropdown__items-list {
            width: 100% !important;
            transform: translate(0, 33.3333px) !important;
        }
    }
}

.edit_user_officeHoursForm {
    @include lt-sm {
        >.ui-grid:not(.whRow) {
            position: relative;
            padding-top: 24px;
            grid-template-columns: calc(50% - 16px) 16px calc(50% - 16px) !important;

            >span.ui-text:first-of-type {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .ui-dropdown__items-list {
            width: 100% !important;
            transform: translate(0, 33.3333px) !important;
        }
    }
}

.chkTrial-mobile {
    padding-right: 0 !important;
    grid-template-columns: 100% !important;
}

.usersconfigGrid>.k-grid-container {
    min-height: 212px;
}


// scroll fix config -> edit

.h-0 {
    height: auto !important;
}

.filterLblRow-txt {
    font-size: 13px;
}

.filterLbl .ui-dropdown__selected-items button .ui-button__content {
    font-size: 13px;
    color: #666;
    font-weight: 400;
}

.filterLbl .ui-dropdown__container {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .3);
}

.filterLbl .ui-dropdown__selected-items button {
    height: 22px;
}

.card-bdr-top {
    border-top: 1px solid rgba(0, 0, 0, .07) !important;
}

.filterLblRow-icn {
    color: rgb(136, 136, 136);
}

.filterLblRow-btn .ui-text {
    font-size: 13px;
    font-weight: 400;
    color: #666;
    padding: 0 2px 0 0;
}

.filterLblRow-btn .ui-flex svg {
    opacity: .6;
}


//userconfig.js_380
.w142fixSm {
    .ui-dropdown__container {
        width: 142px !important;
    }
}