.meetingArea {
  float: left;
  width: 100%;
}

.meetingData {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.meetingDataCol:first-child {
  margin: 3px 0 3px 0;
}

.meetingDataCol {
  float: left;
  background: rgba(98, 100, 167, 0.8);
  width: 100%;
  margin: 3px 0 3px 3px;
}

.meetingDataTitle {
  min-height: 35px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meetingDataTrk {
  float: left;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

.meetingDataTotal {
  float: left;
  width: 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
}

.meetingDataSub {
  float: left;
  width: 50%;
  display: block;
  padding: 10px 0 10px 0;
}

.meetingDataMain-val {
  float: right;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.95);
}

.meetingEnt {
  float: left;
  width: 100%;
  padding: 3px 0 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meetingEnt-val {
  float: left;
  padding: 0 6px 0 0;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.95);
}

.meetingEnt-p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.95);
}

.meetingEnt-val {
  float: left;
  padding: 0 6px 0 0;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.95);
}

.meetingEnt-p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.95);
}

.meetingGraph {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.meetingsGraphTrk:first-child {
  margin: 0;
}

.meetingsGraphTrk {
  width: 100%;
  margin: 0 0 0 1px;
  padding: 15px 0 13px 0;
}

.meetingGraph-p {
  text-align: center;
  padding: 5px 0 0 0;
  font-size: 14px;
  font-weight: 600;
  float: left;
  width: 100%;
  text-align: center;
}

.meetingGraph-area .pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  border: 0.08em solid #7779af;
}

.activity-track .donut-chart {
  margin: 0 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.activity-track .c100.big {
  font-size: 140px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  margin: 0;
}

.meetingGraph-area {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-top: 12px;
}

body.dark {
  .meetingDataCol {
    background: rgba(59, 58, 57, 0.9);
  }
}

body.contrast {
  .meetingDataCol {
    background: rgb(26, 235, 255, 0.9);
  }

  .meetingDataTitle {
    color: rgba(37, 36, 35, 1);
  }

  .meetingDataMain-val {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-val {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-p {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-val {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-p {
    color: rgba(37, 36, 35, 0.95);
  }
}

body.default {
  .meetingDataCol {
    background: rgba(0, 0, 0, 0.025);
  }

  .meetingDataTitle {
    color: rgba(37, 36, 35, 1);
  }

  .meetingDataMain-val {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-val {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-p {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-val {
    color: rgba(37, 36, 35, 0.95);
  }

  .meetingEnt-p {
    color: rgba(37, 36, 35, 0.95);
  }
}


.c100.fs-190 {
  font-size: 195px;
  transition: all 0.5s ease-in-out;
}