//$kpi-bg: $warning; //#FFF;
$kpi-hb1: #dc3545; //$danger; //#F3ABAC;
$kpi-hb2: #28a745; //$success; //#72CE99;
$kpi-hb3: #6264a7; //$primary; //#94A1C1;
$kpi-hb4: #6264a7; //$primary; //#BCB9F3;
$kpi-h1: #6264a7; //$primary; //#979FBC;
$kpi-i1: #6264a7; //$primary; //#3E4E88;
$kpi-i2: #dc3545; //$danger; //#EB5757;
$kpi-i3: #28a745; //$success; //#6FCF97;
$kpi-hb5: #ffc107; //#ffc107;
$kpi-hb6: #28a745; //$success; //#6FCF97;
$kpi-t: #6c757d; //$secondary; //#616161;
$kpi-pb-out: #efefef;


.fs_i_percent {
    font-size: .6em;
}

.fs_i_percentHead {
    font-size: .8em;
}

.opacity_4 {
    opacity: .4;
}

.i-clr-bg-transparent {
    color: transparent !important;
    background: transparent !important;
}

.card365 {
    max-width: 1500px;
    position: absolute;
    // left: 50%;
    // transform: translate(-50%);
}

body.dark {

    .card365kpi,
    .card365kpi-h2 {
        //background-color: $kpi-bg;
        border-radius: 1.25em;
        box-shadow: 0px 8px 24px -4px rgb(0, 0, 0);
        box-shadow: 0px 6px 12px -6px rgb(0, 0, 0);

    }

    .pb365kpi-out {
        background-color: #313131;
        border: 2px solid #000;
    }

    .pb365kpi-in3,
    .pb365kpi-in4 {
        border-left: 2px solid #313131;
    }

}

.card365kpi,
.card365kpi-h2 {
    //background-color: $kpi-bg;
    border-radius: 1.25em;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.1);
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.18);

}

.card365kpi-h {

    .sh1-c1 {

        color: $kpi-hb3;
    }

    .sh1-c2 {

        color: $kpi-hb2;
    }

    .sh1-c3 {

        color: $kpi-hb1;
    }

    .sh1-c4 {

        color: $kpi-hb4;
    }


    .card365kpi-sh1 {

        font-size: 2.125rem;
        line-height: 1.4;

    }

    .card365kpi-sh2 {
        .hct-c1 {

            color: $kpi-t;
        }

        //.card365kpi-hc1 { }

        .hc2-bg1 {

            background-color: $kpi-hb3;
        }

        .hc2-bg2 {

            background-color: $kpi-hb2;
        }

        .hc2-bg3 {

            background-color: $kpi-hb1;
        }

        .hc2-bg4 {

            background-color: $kpi-hb4;
        }

        .hc3-ic1 {

            color: $kpi-i1;
        }

        .hc3-ic2 {

            color: $kpi-i2;
        }

        .hc3-ic3 {

            color: $kpi-i3;
        }

        .hc3-ic4 {

            color: #8c8c8c40;
        }

        .card365kpi-hc2 {
            margin-right: .5em;
            position: relative;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 1.3em;
                color: white;
                background: transparent;
            }

            height: 3em;
            width: 3em;
            border-radius: 1.5em;

        }

        .card365kpi-hc3 {
            display: flex;
            height: 3em;

            i {
                margin: auto .25em;
                font-size: 1.8rem;
                background: transparent;
            }

        }

    }

    .card365kpi-shTime {

        font-size: 1.8rem;
        line-height: 1.7;

    }

}

.graphics365wave {
    z-index: -1;
    position: absolute;
    bottom: 0;
    width: 100%;
    //margin-right: 0;
}

//progress bar start

.pb365-vl1 {
    background-color: $kpi-hb2;
    width: .2em;
    height: 2rem;
    border-radius: .1em;
}

.pb365-vl2 {
    background-color: $kpi-hb1;
    width: .2em;
    height: 2rem;
    border-radius: .1em;
}

.pb365kpi-h1 {
    color: $kpi-hb3;
    min-width: 100px;
    line-height: 1.4;
    font-size: 2.125rem;

    span {
        font-size: 1.5rem;
    }
}

.pb365kpi-h2 {
    color: $kpi-hb2;
    min-width: 100px;
    line-height: 1.4;
    font-size: 2.125rem;

    span {
        font-size: 1.5rem;
    }
}

.pb365kpi-h3 {
    color: $kpi-hb1;
    min-width: 100px;
    line-height: 1.4;
    font-size: 2.125rem;

    span {
        font-size: 1.5rem;
    }
}

.pb365kpi-hx1 {
    color: $kpi-hb6;
    min-width: 100px;
    line-height: 1;
    font-size: 1.5rem;

}

.pb365kpi-hx2 {
    color: $kpi-hb1;
    min-width: 100px;
    line-height: 1;
    font-size: 1.5rem;

}

.pb365kpi-hx3 {
    color: $kpi-hb5;
    min-width: 100px;
    line-height: 1;
    font-size: 1.5rem;

}

.pb365kpi-t1 {
    color: $kpi-t;
}

.pb365kpi-ind1 {
    padding-bottom: .1em;
    font-size: small;
    color: $kpi-t;
}

.pb365kpi-ind2 {

    padding-bottom: .125em;
    font-size: small;
    color: $kpi-hb1;
}

.pb365kpi-ind3 {

    padding-bottom: .125em;
    font-size: small;
    color: $kpi-hb5;
}

.pb365kpi-ind4 {

    padding-bottom: .125em;
    font-size: small;
    color: $kpi-hb6;
}

.pb365kpi-out {
    height: .5em;
    background-color: $kpi-pb-out;
    border: solid 2px $kpi-pb-out;
    border-radius: .25em;
}

.pb365kpi-in1 {
    height: .25em;
    background-color: $kpi-hb3;
    border-radius: .125em;
}

.pb365kpi-in2 {
    background-color: $kpi-hb1;
    height: .25em;
    border-radius: .125em 0 0 .125em;
}

.pb365kpi-in3 {
    background-color: $kpi-hb5;
    height: .25em;
    border-left: 2px solid $kpi-pb-out;
}

.pb365kpi-in4 {
    background-color: $kpi-hb2;
    height: .25em;
    border-left: 2px solid $kpi-pb-out;
    border-radius: 0 .125em .125em 0;
}