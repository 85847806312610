@import "../../../styles/media-queries.scss";

.demoClickDisabled {
    li {
        pointer-events: none;
        opacity: .5;
    }
}

.card-filters {
    display: grid;
    justify-content: center;
    align-items: center;
    height: auto;

    @include lt-sm {
        grid-template-columns: 100% !important;
    }

    >.card-filter-row {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 30% 60%;
    }

    >.card-invalidLicence-row {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 15% 75%;
        margin-bottom: 10px;
    }
}


.k-pdf-export {

    .click-drag-flash {
        visibility: hidden !important;
    }

    .click-drag-flash-2 {
        visibility: hidden !important;
    }

    .click-drag-flash-3 {
        visibility: hidden !important;
    }

}