@import "../../../../styles/media-queries";

.ui-dialog.settingsDialog {
  padding: 1rem;
  width: 60%;

  @include lt-sm {
    width: 90%;
  }

  >div.ui-dialog__content {
    margin-bottom: 0px;
  }

  .ui-header {
    p {
      font-size: 1.1rem;
    }
  }
}

.k-prompt-container,
.k-window-content {
  overflow: hidden !important;
}

.k-window-content form.ui-form {
  //overflow-y: auto !important; (on tab press resulted in scrolls from  x and y axis
}

.multiCard-step-content-holder {
  overflow: initial !important;
}

.formCardSettings {
  .dropdownSection {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-column-gap: 20px;

    >div {
      width: 100%;
    }

    @include lt-sm {
      &.groupFilter {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }
}

.k-animation-container {
  z-index: 1001;
}

.custom-date-container {
  display: grid;
  //grid-column-gap: 10px;
  margin-top: 8px;

  @include lt-sm {
    grid-template-columns: unset;
  }
}

.custom-time-container {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-column-gap: 10px;
  margin-top: 8px;

  @include lt-sm {
    grid-template-columns: unset;
  }
}

.multiCard-step-content-holder {
  width: 200%;
  //min-height: calc(100% - 65px);
  overflow: hidden;
  display: grid;
  transition: transform 0.3s ease;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100% 100%;
}

.availableCards {
  height: 100%;
  overflow-y: auto;

  >div {
    display: inline-block;
    margin: 4px;
    height: auto;
  }
}

.checkBoxTeamisGroup {
  margin-top: 20px;
}

.CB_outside_teams {
  padding: 10px 0 0 24px;
  font-size: 12px;
}