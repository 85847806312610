.k-chart-tooltip {
    background: rgb(66, 66, 66) !important;
}

.k-chart-tooltip-inverse {
    color: white !important;
}

.pTooltipContent {
    margin: 0%;
    font-size: 10px;
}



/*usrPic*/
.topUsrAreaPic {
    float: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.topUsrAreaPic-i {
    float: left;
    padding: 0 5px 0 0;
}

.topUsrAreaPic .contact-list-icon {
    width: 55px;
    height: 55px;
    margin: 0;
}

.contact-list-icon {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 0 0 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 120px;
    -moz-border-radius: 120px;
    -ms-border-radius: 120px;
    border-radius: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.topUsrAreaPic .contact-list-icon .contact-list-icon-txt {
    font-size: 1.375rem;
}

.contact-list-icon-txt {
    font-size: 1.875rem;
    color: #333;
    font-weight: 400;
}

.contact-list-icon-img {
    height: 100%;
    width: 100%;
    float: left;
    overflow: hidden;
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    padding: 0;
    border: 1px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
}

.contact-list-icon-img img {
    width: 100%;
    font-size: 12px;
    transform: scale(1.25);
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -o-transform: scale(1.25);
    -ms-transform: scale(1.25);
}

.topUsrAreaPic-tx {
    float: left;
}

.topUsrAreaPic-h {
    font-size: 1.0625rem;
}

.topUsrAreaPic-p {
    font-size: 13px;
    color: #999;
    word-break: break-all;
}

/*usrPic end*/
.usr-vHdr-pro {
    float: left;
}

.usr-vHdr-qt {
    float: right;
    min-width: 200px;
    text-align: right;
}

.usr-vHdr-qtTx {
    float: left;
    width: 100%;
    padding: 0 0 7px 0;
    font-size: 1rem;
}

.usr-vHdr-qtVal {
    float: left;
    width: 100%;
}

.usr-view {
    padding: 20px 20px 18px 20px;
    float: left;
    width: 100%;
}

.usr-vHdr {
    float: left;
    width: 100%;
    padding: 0 10px 20px 10px;
}

.usr-vDtl {
    float: left;
    width: 100%;
    padding: 0 10px 0 10px;
}

.usr-vDtlRowTrk {
    float: left;
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
}

.vDtl-t {
    font-size: 1rem;
    float: left;
    width: 100%;
    border-bottom: 1px solid;
    padding: 9px 13px 9px 13px;
}

.vDtl-inr {
    float: left;
    width: 100%;
    padding: 0 15px 0 15px;
}

.calcH43 {
    height: calc(100% - 2.6875rem);
}

.calcH43 {
    height: calc(100% - 2.6875rem);
}

.vDtl-inrRow:last-child {
    border: none;
}

.vDtl-inrRow {
    float: left;
    width: 100%;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, .04);
}

.vDtl-inrRow-p {
    font-size: 0.8125rem;
    color: #777;
}

.vDtl-inrRow-val {
    font-size: 0.8525rem;
    font-weight: 600;
    text-align: right;
}

/*pointLbl*/
.pointLbl {
    float: right;
}

.pointLblTrk {
    float: right;
    display: flex;
    margin: 0 0 0 10px;
    font-size: 0.875rem;
}

.pointLblTrk:last-child {
    margin: 0;
}

.pointLblTrk-h {
    font-weight: 600;
    padding: 0 3px 0 0;
    font-size: 0.8525rem;
}

.pointLblTrk-p {
    color: #777;
    font-size: 0.8125rem;
}

.flex-1 {
    flex: 1;
}

.h-90 {
    height: 90% !important;
}

.h-87 {
    height: 87% !important;
}

.h-83 {
    height: 83% !important;
}

.h-50 {
    height: 50% !important;
}

.vDtlRow {
    float: left;
    width: 100%;
    height: 100%;
}

.vDtlRowTrk {
    float: left;
    width: 100%;
    height: 100%;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .04);
    display: flex;
    align-items: center;
    justify-content: center;
}

.vDtlRowTrk-val {
    font-size: 0.8525rem;
    font-weight: 600;
    padding: 0 5px 0 0;
}

.vDtlRowTrk-p {
    font-size: 0.8125rem;
    color: #777;
}

.vDtlRowTrkLi {
    padding: 3px 0 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.users-small .topUsrAreaPic .contact-list-icon {
    width: 30px;
    height: 30px;
}

.users-small .topUsrAreaPic-i .contact-list-icon .contact-list-icon-txt {
    font-size: 11px;
}

.tblUsr.moreUsr.users-small .topUsrAreaPic:last-child .topUsrAreaPic-i .contact-list-icon .contact-list-icon-txt {
    font-size: 12px;
}

.manage-ins {
    padding: 0 0 12px 14px;
    position: relative;
}

.manage-ins-axis-x,
.manage-ins-axis-y {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.manage-ins-axis-x span,
.manage-ins-axis-y span {
    position: relative;
}

.manage-ins-axis-x {
    top: 100%;
    left: 3px;
    border-top: 1px solid #999;
}

.manage-ins-axis-y {
    transform: rotate(-90deg);
    transform-origin: 0 0;
    left: -18px;
    bottom: -21.5px;
    width: 212px;
    border-bottom: 1px solid #999;
}

.ui-avt-shadow {
    border-radius: 50%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

@media screen and (max-width: 1700px) and (min-width: 600px) {
    .vDtlRowTrkLi {
        display: block;
    }
}

@media screen and (max-width: 767px) {

    .vDtl-inr .pl-0,
    .px-0 {
        padding-left: 15px !important;
    }

    .usr-vDtl .col-md-4 {
        height: 100%;
    }
}

/*pointLbl end*/


.tileTraks .vDtlRowTrkLi {
    display: block;
}

.tblUsr.moreUsr {

    height: 60px;

}