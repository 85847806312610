body.dark {
    .text-dark-mode {
        color: gray;
    }

}

body.contrast {
    .text-dark-mode {
        color: gray;
    }
}

.userBox {
    padding: 2px;
    border-radius: 8px;
    display: inline-block;
}

.collab-network {
    box-shadow: rgba(0 0 0 /.4) 15px 0px 12px -8px;
    overflow: auto;
    border-radius: .25rem !important;
    min-width: 200px;
    min-height: 82px;
}

.collab-network2 {
    box-shadow: rgba(0 0 0 /.25) 15px 0px 12px -8px;
    overflow: auto;
    border-radius: .25rem !important;
    min-width: 200px;
    min-height: 82px;
}

.z-5 {
    z-index: 5;
}

.z-4 {
    z-index: 4;
}

.z-3 {
    z-index: 3;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-22 {
    font-size: 22px;
}

.collab-network-cards {
    overflow: auto;
    max-height: 428px !important;
}

.collab-network-cards-m {
    overflow: auto;
    max-height: 112px !important;
}

.collab-network-avtar {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 50%;
}

.bg-icon-cst {
    z-index: -1;
    position: absolute;
    width: 100%;
}

.text-shadow-cst {
    text-shadow: 0 0 4px rgba(0 0 0 /.5);
}

.lh-1-cst {
    line-height: 1.125em;
    margin-top: .125em;
}

.cursor-default {
    cursor: default;
}