
 // team governance

 body.dark {
    .lbl-row-cst {
        background-color: #252525;
    }
 }


 .lbl-row-cst {
     cursor: pointer;
     background-color: #fff;
     align-items: center;
     border-left: 2px solid #a2a4d2;
     margin: .25em 0;
     transition: all ease-in-out .2s;
     &:hover {
         border-left: 2px solid #0051a8;
         .lbl-col-p-cst {
             color: #0051a8;
             transition:all ease-in-out .3s;
         }
         .lbl-col-h-cst {
             background-color: #0051a8;
             transition:all ease-in-out .3s;
         }
         transition:all ease-in-out .3s;
     }
 
     &.active {
         background-color: #007bff;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         border-left: 2px solid #007bff;
         .lbl-col-p-cst {
             // color: #007bff;
             color: #fff;
         }
         .lbl-col-h-cst {
             background-color: #007bff;
             border-left: 1px solid #fff;
         }
         transition:all ease-in-out .2s;
     }
 
     .lbl-col-p-cst {
         padding: 0 .5em;
         color: #6c757d;
         transition:all ease-in-out .3s;
     }
 
     .lbl-col-h-cst {
         font-weight: 600;
         color: #fff;
         background-color: #a3a8d2;
         padding: .125em .5em;
         transition: all ease-in-out .2s;
     }
 
 }
 
 
 
 
 
 