.default-segment-container{
    min-height: 50vh;
    width: 100%;

    &.auth-form-container{
        min-height: 100vh;
    }    
}
.default-segment{
    min-width: 400px;

    .ui-button{
        margin-top: 20px;
    }
}

body.default{
    .default-segment{
        background-color: rgb(51, 52, 74);

        .ui-text{
            color: #FFF;
        }
    }
}
body.dark{
    .default-segment{
        background-color: rgb(41, 40, 40);

        .ui-text{
            color: #FFF;
        }
    }
}
body.contrast{
    .default-segment{
        background-color: rgb(26, 235, 255);

        .ui-text{
            color: rgb(37, 36, 35);
        }
    }
}
