@import "../../../../styles/media-queries.scss";

.ui-dialog__overlay {
  z-index: 10010 !important;
}

.ui-dialog.settingsDialog {
  padding: 1rem;
  width: 60%;

  @include lt-sm {
    width: 90%;
  }

  >div.ui-dialog__content {
    margin-bottom: 0px;
  }

  .ui-header {
    p {
      font-size: 1.1rem;
    }
  }
}

.formCardSettings {
  &.isUpdate {
    max-height: calc(100% - 28px);
  }

  .dropdownSection {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-column-gap: 20px;

    >div {
      width: 100%;
    }

    @include lt-sm {
      &.groupFilter {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }
}

.k-animation-container {
  z-index: 1001;
}

.custom-time-container {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-column-gap: 10px;
  margin-top: 8px;

  @include lt-sm {
    grid-template-columns: unset;
  }
}

.livePreviewDialog {
  >.k-dialog {
    min-height: 80%;
  }

  >.ui-dialog__content {
    overflow-x: hidden;
  }
}

.checkBoxTeamisGroup {
  margin-top: 20px;
}