.meetingHabit {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.teamHabits .progressBoxRow {
    padding: 0 15px 0 0;
}

.progressBoxData {
    display: flex;
    justify-content: space-between;
    font-size: 13px !important;
}

.progressBoxRow {
    margin-bottom: 4px;
}

.lh-1 {
    line-height: 1;
}

.space-evenly {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}