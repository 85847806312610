.smryDiv {
   // float: left;
    height: 100%;
    width: 100%;
  //  display: flex;
  //  justify-content: space-between;
}

.smrL {
    display: inline;
    width: calc(100% - 200px);
}

.smrR {
    display: inline;
    width: 200px;
}

.teamActivity {
    height: 85%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    background-color: rgb(240, 240, 240);
    font-size: 0.95rem;
}

.insightsLable {
    height: 30px;
    padding-top: 3px;
    background-color: rgb(200, 198, 196);
    text-align: center;
    font-weight: bold;
    align-items: center;
}

.st_unavailable {
    color: #5d5d5d;
}


.callTrack-h {
    font-weight: 600;
    width: 100%;
    text-align: right;
}

.usr-vHdr-qtVal {
    float: left;
    width: 100%;
}

.callTrack-h .progress {
    height: 12px;
    font-size: 8px;
}