@import "../../../styles/variables";

// .groupedGrid table > thead > tr:first-of-type > th:not(:first-child) {
//   text-align: center;
// }

.st_good,
.st_average,
.st_poor,
.st_unavailable {
  > svg {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.st_good {
  color: rgb(35, 123, 75);
}

.st_average {
  color: rgb(248, 210, 42);
}

.st_poor {
  color: rgb(196, 49, 75);
}

.st_unavailable {
  color: #5d5d5d;
}
