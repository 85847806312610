a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  padding: 0;
}

html {
    font-size: calc(1em * 0.9) !important
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.nav-item > .dropdown-menu {
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0;

  .dropdown-item{    
    outline: none !important;
  }
}

body.default{
  .nav-link,
  a.navbar-brand{
    color: #252423;
  }

  .nav-item > .dropdown-menu {
    color: #252423!important;
    background-color: #fff;
    border-color:  rgba(0,0,0,.15);
    
    .dropdown-item.active, .dropdown-item:active {
      color: #fff;
      background-color: #6264a7;
    }
    
    .dropdown-item:focus, .dropdown-item:hover {
        color: #252423;
        background-color: rgb(243, 242, 241);
    }
  }
}
body.dark{
  .nav-link,
  a.navbar-brand{
    color: #fff;
  }

  .nav-item > .dropdown-menu {
    color: #fff;
    background-color: rgb(45, 44, 44);
    border-color:  rgba(0,0,0,.15);
    
    .dropdown-item{
      color: rgb(200, 198, 196);
    }
    .dropdown-item.active, .dropdown-item:active {
      color: #fff;
      background-color: rgb(32, 31, 31);
    }
    
    .dropdown-item:focus, .dropdown-item:hover {
        color: #FFF;
        background-color: rgb(59, 58, 57);
    }
  }
}
body.contrast{
  .nav-link,
  a.navbar-brand{
    color: #ffff01;
  }

  .nav-item > .dropdown-menu {
    color: #ffff01 !important;
    background-color: rgb(0, 0, 0);
    border-color:  rgba(0,0,0,.15);

    .dropdown-item{
      color:#ffff01;
    }
    .dropdown-item.active, .dropdown-item:active {
      color:#252423;
      background-color: rgb(26, 235, 255);
    }
    
    .dropdown-item:focus, .dropdown-item:hover {
      color:#252423;
        background-color: #ffff01;
    }
  }
}
