.topUsrArea {
  &:first-child {
    padding: 0 10px 0 0;
    border-left: none;
  }
  &:not(:first-child) {
    padding: 0 10px;
  }
  &:last-child {
    padding: 0 0 0 10px;
  }
}

/*topUsrList*/
.activity-view {
  float: left;
  width: 100%;
  height: 100%;
}
.topUsrList {
  float: left;
  width: 100%;
 // height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 15px 15px 10px 15px;
 // align-items: center;
}
.topUsrArea {
  float: left;
 // width: 190px;
  border-left: 2px solid rgba(0, 0, 0, 0.14);
  padding: 0 10px 0 15px;
  margin: 0;
}
.topUsrAreaPic {
  float: left;
  display: flex;
  align-items: center;
}
.topUsrAreaPic-i {
  float: left;
  padding: 0 5px 0 0;
}
.contact-list-icon {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 0 0 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topUsrAreaPic .contact-list-icon {
  width: 60px;
  height: 60px;
  margin: 0;
}
.topUsrAreaPic .contact-list-icon .contact-list-icon-txt {
    font-size: 22px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}
.contact-list-icon-img {
  height: 100%;
  width: 100%;
  float: left;
  overflow: hidden;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  padding: 0;
  border: 1px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.contact-list-icon-img img {
  width: 100%;
  font-size: 12px;
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
}
.topUsrAreaPic-tx {
  float: left;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 18px;
}
.topUsrAreaDtl {
  float: left;
  width: 100%;
  padding: 10px 0 0 0;
}
.topUsrAreaDtl-t {
  font-size: 0.9rem;
  font-weight: 600;
  /* color: #000; */
}
.topUsrAreaDtlLbl {
  float: left;
  width: 100%;
}
.topUsrAreaDtlLblRow {
  float: left;
  width: 100%;
  padding: 2px 0 3px 0;
  display: flex;
  align-items: center;
}
.topUsrAreaDtlLblRow-h {
  font-size: 0.85rem;
  /* color: #333; */
  min-width: 85px;
  max-width: 85px;
  line-height: 16px;
}
.topUsrAreaDtlLblRow-dot {
  float: left;
  padding: 0 10px;
  /* color: rgba(0,0,0,.5); */
  opacity: 0.5;
}
.topUsrAreaDtlLblRow-val {
  font-size: 0.85rem;
  font-weight: 600;
}
