h1 {
  font-size: 2.5rem;
  font-family: "Ubuntu";
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.75rem;
}

h5 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

a {
  font-size: 1rem;
}
